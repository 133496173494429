import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <section className={"svgBoxRectanguloBottonRigth"}>
            <div className="w-full relative content_video svgHerosection2 flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/25"></div>

                <div className="z-20 flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[10px] md:bottom-[50px] lg:bottom-[10px] md:text-start text-center text-white">
                    <div className="w-full text-center">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[1]?.slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbSlogan?.[8]?.slogan}</p>
                        <img className="w-[200px] md:w-[250px] mx-auto object-cover" src="https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/sastifaction.png?alt=media&token=bedfa19a-0eaa-44d2-b824-86387650f3ab" alt="emblema de google" />
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default HeroVideo;